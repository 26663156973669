import { TiArrowLeftThick, TiArrowRightThick } from "react-icons/ti";
export const RightLeftArror = (props: {
  onBack: () => void;
  onForward: () => void;
  className?: string | "";
}) => {
  return (
    <div className={`flex space-x-8 ${props.className}`}>
      <button
        onClick={() => props.onBack()}
        className="w-fit cursor-pointer shadow-2xl shadow-[#f8744466] rounded-full p-3 mb-12 text-[#fff1ed] text-5xl bg-[#f87544]"
      >
        <TiArrowLeftThick />
      </button>
      <button
        onClick={() => props.onForward()}
        className="w-fit cursor-pointer shadow-2xl shadow-[#f8744466] rounded-full p-3 mb-12 text-[#fff1ed] text-5xl bg-[#f87544]"
      >
        <TiArrowRightThick />
      </button>
    </div>
  );
};
