interface Props {
  color?: string;
  text?: string;
  label: string;
  rounded?: string;
  padding?: string;
  className?: string | "";
  type?: "button" | "submit" | "reset";
  onClick: () => void;
}

export const Button: React.FC<Props> = ({
  color,
  text,
  label,
  type,
  className,
  padding,
  rounded,
  onClick,
}) => {
  return (
    <button
      onClick={() => onClick()}
      type={type ?? "button"}
      className={`${className} ${rounded ? rounded : "rounded-[2rem]"} ${
        padding ? padding : "px-14 py-[18px]"
      }  font-semibold ${color ?? "bg-[#f87544]"} ${text ?? "text-zinc-50"}`}
    >
      {label}
    </button>
  );
};
