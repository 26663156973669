import { useEffect, useRef } from "react";
import { FiMonitor, FiRepeat, FiTrendingUp } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import { FeatureCard } from "../components/items/FeatureCard";
import { FeatureCardwithButton } from "../components/items/FeatureCardwithButton";
import { StatCard } from "../components/items/StatCard";
import MainLayout from "../components/layouts/MainLayout";
import { ContactUs } from "../components/sections/ContactUs";
import { HomeMainSection } from "../components/sections/HomeMainSection";
import { ServiceSection } from "../components/sections/ServiceSection";
import { TestimonialSection } from "../components/sections/TestimonialSection";
import Monitoring from "../assets/monitoring.png";
import Integrate from "../assets/integrate.png";

const Home = () => {
  const contactUsRef = useRef();
  const servicesRef = useRef();
  const featuresRef = useRef();
  const scrollTo = (e) => {
    e.current.scrollIntoView({
      behavior: "smooth",
    });
  };
  const { hash } = useLocation();
  useEffect(() => {
    switch (hash) {
      case "#contactus":
        scrollTo(contactUsRef);
        break;
      case "#features":
        scrollTo(featuresRef);
        break;
      case "#services":
        scrollTo(servicesRef);
        break;
    }
  }, [hash]);
  return (
    <MainLayout page="home">
      <HomeMainSection contactUs={() => scrollTo(contactUsRef)} />
      <div className="px-14 lg:px-32 mb-16 mt-14 grid grid-cols-2 lg:grid-cols-4">
        <StatCard
          text="30%"
          subtext="Productivity enhancement in a single quarter"
        />{" "}
        <StatCard
          text="76%"
          subtext="Improves accuracy in minor and major business operations"
        />{" "}
        <StatCard text="25%" subtext="Better Resource Utilization" />{" "}
        <StatCard
          text="30X"
          subtext="The overall increase in Business Revenue"
        />
      </div>
      <div ref={featuresRef} />
      <div className="px-14 lg:px-32 grid grid-cols-1 lg:grid-cols-2 gap-6">
        <FeatureCard
          title="Smooth transition to automation"
          subtext="Easily migrates a company's existing processes onto
           Orgosys and quickly automates the supply chain, logistics, 
           and operations for better performance."
          icon={<FiTrendingUp />}
        />
        <FeatureCard
          className="row-span-2 pr-28"
          image={Integrate}
          title="Integrate seamlessly with other systems"
          subtext="Our platform seamlessly integrates with other cloud
          applications like Salesforce, Oracle, SAP, etc., making data
          exchange quick and easy."
          icon={<FiRepeat />}
        />
        <FeatureCard
          title="Eliminate manual efforts."
          subtext="Our Al-powered automation platform reduces human efforts
          significantly and increases productivity."
          icon={<FiMonitor />}
        />
        <FeatureCardwithButton
          onClick={() => scrollTo(contactUsRef)}
          className="lg:col-span-2"
          image={Monitoring}
          title="Real-time tracking & monitoring"
          subtext="Keep an eye on all the organizational activities in real time
          with our dashboard. You can monitor each process in detail
          without any lag."
        />
      </div>
      <div ref={servicesRef} />
      <ServiceSection />
      <TestimonialSection />
      <div ref={contactUsRef} />
      <ContactUs />
    </MainLayout>
  );
};

export default Home;
