import { motion, useAnimation } from "framer-motion";
import Arm from "../../assets/home-page-imgs/arm-img.png";
import Background from "../../assets/home-page-imgs/background-img.png";
import Boy from "../../assets/home-page-imgs/boy-img.png";
import Girl1 from "../../assets/home-page-imgs/girl1-img.png";
import Girl2 from "../../assets/home-page-imgs/girl2-img.png";
import SomePeople from "../../assets/home-page-imgs/people-img.png";
import People3 from "../../assets/home-page-imgs/rev-img-mobile.png";
import AllPeople from "../../assets/home-page-imgs/rev-img.png";
import AOS from "aos";
import "../../../node_modules/aos/dist/aos.css";

export const HomeAnimation = (props: { onClick: () => void }) => {
  const lgImgAnimation = useAnimation(),
    rgImgAnimation = useAnimation();
  const handleMouseMove = (e) => {
    const { clientX } = e;
    const offsetFactor = 70;
    lgImgAnimation.start({
      x: (clientX - window.innerWidth / 2) / offsetFactor,
    });
    rgImgAnimation.start({
      x: (window.innerWidth / 2 - clientX) / offsetFactor,
    });
  };
  AOS.init({
    once: true,
  });
  return (
    <div
      className="mt-[15vh] lg:mt-[18.5vh] text-center"
      onMouseMove={(e) => handleMouseMove(e)}
    >
      <h1 data-aos="slide-up" className="font-bold text-6xl lg:text-7xl px-10">
        We'll Code. You'll Relax.
      </h1>
      <div className="relative mt-6 overflow-hidden">
        <img
          data-aos="slide-up"
          className="object-cover h-[30vh] sm:h-[50vh] w-screen z-0"
          src={Background}
          alt=""
        />
        <img
          data-aos="slide-up"
          className="block md:hidden h-52 absolute bottom-0 z-10 left-[calc((100vw-340px)/2)]"
          src={People3}
          alt=""
        />
        <img
          data-aos="slide-up"
          className="hidden md:block lg:hidden absolute bottom-0 h-40 z-10 left-[calc((100vw-747px)/2)]"
          src={AllPeople}
          alt=""
        />
        <motion.img
          data-aos="slide-up"
          animate={rgImgAnimation}
          className="block md:hidden lg:block h-40 lg:h-[12.5rem] absolute bottom-0 lg:-bottom-14 left-[calc((100vw-340px)/2+255px)] lg:left-[calc((100vw-1022px)/2+155px)]"
          src={Girl1}
          alt=""
        />
        <motion.div
          data-aos="slide-up"
          className="hidden lg:block z-10 absolute bottom-[70px] left-[calc((100vw-1022px)/2+588px)]"
          animate={rgImgAnimation}
        >
          <motion.img
            animate={{ rotate: 2.5, x: 0.4 }}
            transition={{
              from: -2,
              duration: 0.6,
              ease: "easeInOut",
              type: "tween",
              repeatType: "reverse",
              repeat: Infinity,
            }}
            className="h-14"
            src={Arm}
            alt=""
          />
        </motion.div>
        <motion.img
          data-aos="slide-up"
          className="hidden lg:block absolute h-56 -bottom-14 left-[calc((100vw-1022px)/2)]"
          animate={lgImgAnimation}
          src={SomePeople}
          alt=""
        />
        <motion.img
          data-aos="slide-up"
          animate={rgImgAnimation}
          className="hidden lg:block h-[12.5rem] absolute -bottom-14 left-[calc((100vw-1022px)/2+675px)]"
          src={Boy}
          alt=""
        />
        <motion.img
          data-aos="slide-up"
          animate={rgImgAnimation}
          className="hidden lg:block h-[12.5rem] z-30 absolute -bottom-14 left-[calc((100vw-1022px)/2+510px)]"
          src={Girl2}
          alt=""
        />

        <div
          data-aos="slide-up"
          className="hidden md:block text-7xl font-bold text-[#f87544] z-10 absolute top-0 left-[calc((100vw-523px)/2)]"
          style={{ fontFamily: "Amatic SC" }}
        >
          Your IT Outsourcing Pals!
        </div>
        <div
          data-aos="slide-up"
          className="hidden md:block absolute top-32 left-[calc((100vw-270px)/2)]"
        >
          <button
            onClick={() => props.onClick()}
            className="z-10 p-6 rounded-xl font-bold bg-[#1F2C3C] text-white hover:text-[#f87544] hover:-translate-y-0.5 transform duration-300 ease-out"
          >
            Consult our Growth Advisors
          </button>
        </div>
      </div>
    </div>
  );
};
