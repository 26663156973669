import { PortfolioItem } from "../items/PortfolioItem";
import SamTree from "../../assets/portfolio-imgs/samtree.png";
import Omilia from "../../assets/portfolio-imgs/OmiliaDesktop.jpg";
import OmiliaMobile from "../../assets/portfolio-imgs/OmiliaMobile.png";
import SamTreeSS from "../../assets/portfolio-imgs/samTreeMobile.png";
import Greenhouse from "../../assets/portfolio-imgs/Greenhouse.png";
import GreenhouseMobile from "../../assets/portfolio-imgs/GreenhouseMobile.png";
import Jubi from "../../assets/portfolio-imgs/Jubi.jpg";
import JubiMobile from "../../assets/portfolio-imgs/JubiMobile.png";
import { useEffect } from "react";
import MainLayout from "../../components/layouts/MainLayout";

const Portfolio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <MainLayout page="portfolio">
      <div className="mt-44 mb-6 text-5xl text-center font-semibold">
        Check Out Our
        <br /> Latest Works
      </div>
      <div className="bg-[#f87544] mx-auto w-16 h-1 mb-14"></div>
      <PortfolioItem
        name={"Jubilee"}
        heading={"A mobile app to digitalize & expand KFC's digital footprint"}
        linktext={"View"}
        img={Jubi}
        phoneImg={JubiMobile}
        rev={true}
        subtext={
          "A global franchise food delivery app that enables users to track real-time status of their order delivery along with other innovative features."
        }
        onClick={() => null}
        theme="light"
      />
      <PortfolioItem
        name={"Sam Switch"}
        heading={
          "A mobile app to make cryptocurrency transactions mainstream for consumers"
        }
        linktext={"View"}
        img={SamTree}
        phoneImg={SamTreeSS}
        subtext={
          "A leading digital platform that allows end-users to store, transact, and convert cryptocurrencies in fiat currencies."
        }
        onClick={() => window.open("https://isamtree.com/")}
        theme="dark"
      />
      <PortfolioItem
        name={"Omilia"}
        heading={"A mobile app to digitalize & expand KFC's digital footprint"}
        linktext={"View"}
        img={Omilia}
        phoneImg={OmiliaMobile}
        rev={true}
        subtext={
          "A global franchise food delivery app that enables users to track real-time status of their order delivery along with other innovative features."
        }
        onClick={() => null}
        theme="dark"
      />
      <PortfolioItem
        name={"Greenhouse"}
        heading={
          "A mobile app to make cryptocurrency transactions mainstream for consumers"
        }
        linktext={"View"}
        img={Greenhouse}
        phoneImg={GreenhouseMobile}
        subtext={
          "A leading digital platform that allows end-users to store, transact, and convert cryptocurrencies in fiat currencies."
        }
        onClick={() => null}
        theme="light"
      />
    </MainLayout>
  );
};

export default Portfolio;
