import {
  motion,
  useMotionValue,
  useScroll,
  useTransform,
  useViewportScroll,
} from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { FiArrowRight } from "react-icons/fi";
import { MobileView } from "./MobileView";
import AOS from "aos";

export const PortfolioItem = (props: {
  name: string;
  heading: string;
  linktext: string;
  className?: string;
  img: string;
  phoneImg: string;
  subtext: string;
  rev?: boolean;
  theme: "light" | "dark";
  onClick: () => void;
}) => {
  const { scrollY } = useScroll(),
    ref = useRef(null),
    [top, setTop] = useState(0),
    [bottom, setBottom] = useState(500);
  const y = useTransform(
    scrollY,
    [top - window.innerHeight, bottom],
    ["5vw", "-5vw"]
  );
  AOS.init({
    once: true,
  });
  useEffect(() => {
    if (!ref) return;
    setTop(ref.current.offsetTop);
    setBottom(
      ref.current.offsetTop + ref.current.getBoundingClientRect().height
    );
  }, [ref]);
  return (
    <div
      ref={ref}
      className={`md:h-[45vw] mb-[5vw] md:mb-[3vw] relative md:flex items-center justify-between w-full gap-[10vw] ${
        props.className
      } ${props.rev ? "flex-row-reverse" : "flex-row"}`}
    >
      <motion.div
        data-aos="slide-up"
        style={{ y: y }}
        className={`md:absolute md:w-[20vw] w-fit mx-auto ${
          props.rev ? "md:left-[43vw]" : "md:right-[43vw]"
        }`}
      >
        <MobileView image={props.phoneImg} theme={props.theme} />
      </motion.div>
      <div className="w-full md:w-[20rem] lg:w-full">
        <div className="md:max-w-[325px] xl:max-w-[425px] mt-10 px-5 md:mt-0 md:mx-auto xl:text-lg">
          <h2 className="font-semibold text-3xl xl:text-4xl">{props.name}</h2>
          <p className="font-semibold mt-2.5">{props.heading}</p>
          <p className="mt-2.5">{props.heading}</p>
          <div
            onClick={() => props.onClick()}
            className="relative text-[#f87544] flex transform duration-300 ease-out group mt-5 hover:cursor-pointer w-fit"
          >
            <span className="text-xl mr-3">{props.linktext}</span>
            <div className="w-5 relative overflow-hidden flex items-center">
              <span className="absolute flex items-center -left-10 group-hover:left-0 transition-all ease-out duration-300">
                <FiArrowRight />
                <FiArrowRight className=" ml-6" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <img
        className={`hidden md:block w-[45vw] h-[45vw] object-contain`}
        src={props.img}
        alt={props.name}
      />
    </div>
  );
};
