import { RightLeftArror } from "../items/RightLeftArrow";
import { Tag } from "../items/Tag";
import { TestimonialCard } from "../items/TestimonialCard";
import Quotes from "../../assets/quotes.png";

export const TestimonialSection = () => {
  return (
    <div className="bg-white py-32 px-14 lg:px-32 relative">
      <div className="flex justify-between items-center">
        <div className="w-[30%]">
          <Tag label="testimonials" />
          <h1
            style={{ lineHeight: 1.5 }}
            className="text-[56px] mt-5 font-semibold"
          >
            What our Clients say?
          </h1>
        </div>
        {/* <RightLeftArror onBack={() => null} onForward={() => null} /> */}
      </div>
      <div className="grid grid-cols-2 gap-8 mt-14">
        <TestimonialCard
          info={{
            name: "John Doe",
            designation: "CEO, Random Company",
            review: `We have used Orgosys warehouse and inventory automation for 
            managing our materials which have optimized our production 
            and increased our run rate. There is drastically a cut down of 
            losses and feedback of satisfied customers.`,
            rating: 4.5,
            img: "https://www.befunky.com/images/prismic/d5660d64-faac-42a9-81fd-137f392812a3_hair-color.jpg?auto=avif,webp&format=jpg&width=1920&height=1920&fit=bounds",
          }}
        />
        <TestimonialCard
          info={{
            name: "John Doe",
            designation: "CEO, Random Company",
            review: `Our inventory would always carry dead stock, and manually
            managing this had caused a lot of capital loss to the firm. After
            adopting Orgosys warehousing solutions, we have efficiently
            shifted to cloud-based logistics and manage orders better and
            regulate cash flow.`,
            rating: 5,
            img: "https://t4.ftcdn.net/jpg/02/14/74/61/360_F_214746128_31JkeaP6rU0NzzzdFC4khGkmqc8noe6h.jpg",
          }}
        />
      </div>
      <img src={Quotes} className="absolute top-36 -left-40 w-96" />
    </div>
  );
};
