import { InlineWidget } from "react-calendly";
import Background from "../../assets/contactus-bg.png";
// import { Button } from "../items/Button";
// import { InputField } from "../items/InputField";

export const ContactUs = () => {
  return (
    <div className="h-screen relative flex items-center justify-center">
      <img
        src={Background}
        className="h-full absolute top-0 bottom-0 object-cover"
      />
      <div className="bg-white rounded-md flex justify-between w-full max-w-[1080px] py-14 px-20 z-10 ">
        <div className="min-w-[320px] w-[44%] border border-[#F87544] rounded-md">
          <InlineWidget
            styles={{
              height: "500px",
            }}
            pageSettings={{
              hideEventTypeDetails: true,
              hideLandingPageDetails: true,
              primaryColor: "F87544",
            }}
            url="https://calendly.com/shubh-agrwl/15min"
          />
        </div>

        {/* <form className="space-y-8 w-[38%] py-12 text-lg">
          <InputField
            label="Name"
            placeholder="Enter your name"
            onChange={(e) => console.log(e)}
            type={"text"}
          />
          <InputField
            label="Email Id"
            placeholder="Enter your email"
            onChange={(e) => console.log(e)}
            type={"text"}
          />
          <InputField
            label="Your Message"
            placeholder="Type your message.."
            onChange={(e) => console.log(e)}
            type={"area"}
          />
          <Button
            className="w-full font-medium"
            rounded="rounded-md"
            padding="py-2 px-5"
            label={"Submit"}
            type="submit"
            onClick={() => null}
          />
        </form> */}
        <div className="ml-20 -mb-24 py-20 px-16 w-[48%] flex flex-col justify-center rounded-md text-white bg-[#f87544] space-y-1.5">
          <p className="text-xl">Get in Touch</p>
          <h1
            style={{ lineHeight: 1.25 }}
            className="text-[56px] font-semibold"
          >
            Schedule a Meeting
          </h1>
          <p>
            Schedule a 15 mins meeting with us by filling the neccessary
            information.
          </p>
        </div>
      </div>
    </div>
  );
};
