import LogoDark from "../../assets/OrgosysLogoDark.png";
import { FaFacebook, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { HiLocationMarker, HiMail, HiMap, HiPhone } from "react-icons/hi";

interface Props {
  theme?: string;
}

export const Footer: React.FC<Props> = ({ theme }) => {
  return (
    <footer className="bg-[#11181f] w-screen px-14 lg:px-32 divide-y divide-zinc-600">
      <div className=" pt-24 pb-32 text-white items-center grid sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-5 justify-between  gap-y-8">
        <div className="w-[19rem] sm:col-span-2 md:col-span-1 xl:col-span-2">
          <img className=" h-7" src={LogoDark} alt="Orgosys" />
          <div className="mt-5">Searching for a tagline...</div>
          <div className="w-fit flex mt-7 text-[#1F2C3C] space-x-2.5">
            <div className="p-2 rounded-full bg-white hover:text-[#f87544] transition duration-300 ease-out hover:-translate-y-0.5 hover:cursor-pointer">
              <FaFacebook />
            </div>
            <div className="p-2 rounded-full bg-white hover:text-[#f87544] transition duration-300 ease-out hover:-translate-y-0.5 hover:cursor-pointer">
              <FaTwitter />
            </div>
            <div className="p-2 rounded-full bg-white hover:text-[#f87544] transition duration-300 ease-out hover:-translate-y-0.5 hover:cursor-pointer">
              <FaLinkedinIn />
            </div>
          </div>
        </div>
        <div className="w-[19rem] md:w-fit">
          <h2 className="font-semibold text-xl text-zinc-500">Company</h2>
          <div className=" flex flex-col mt-4 space-y-1.5 text-lg">
            <div className="hover:text-gray-300 hover:cursor-pointer">Home</div>
            <div className="hover:text-gray-300 hover:cursor-pointer">
              About Us
            </div>
            <div className="hover:text-gray-300 hover:cursor-pointer">
              Career
            </div>
            <div className="hover:text-gray-300 hover:cursor-pointer">
              Services
            </div>
          </div>
        </div>
        <div className="w-[19rem] md:w-fit">
          <h2 className="font-semibold text-xl text-zinc-500">Other links</h2>
          <div className=" flex flex-col mt-4 space-y-1.5 text-lg">
            <div className="hover:text-gray-300 hover:cursor-pointer">
              Link 1
            </div>
            <div className="hover:text-gray-300 hover:cursor-pointer">
              Link 2
            </div>
            <div className="hover:text-gray-300 hover:cursor-pointer">
              Link 3
            </div>
            <div className="hover:text-gray-300 hover:cursor-pointer">
              Link 4
            </div>
          </div>
        </div>
        <div className="w-[19rem] md:w-fit">
          <h2 className="font-semibold text-xl text-zinc-500">
            Reach out to us
          </h2>
          <div className=" flex flex-col mt-4 space-y-1.5 text-lg">
            <div className="hover:text-gray-300 hover:cursor-pointer flex whitespace-nowrap">
              <HiLocationMarker className="mt-1 mr-2" />
              B-9, Gali No. 6/1, West Arjun Nagar
            </div>
            <div className="hover:text-gray-300 hover:cursor-pointer flex">
              <HiMap className="mt-1 mr-2" />
              Delhi, India 110029
            </div>
            <div className="hover:text-gray-300 hover:cursor-pointer flex">
              <HiMail className="mt-1 mr-2" />
              sales@orgosys.com
            </div>
            <div className="hover:text-gray-300 hover:cursor-pointer flex">
              <HiPhone className="mt-1 mr-2" />
              +91 99539 40590
            </div>
          </div>
        </div>
      </div>
      <div className="p-4 pb-12 text-zinc-500 sm:flex sm:justify-between full text-xs">
        <div className="flex justify-center space-x-5 md:space-x-20">
          <p className="hover:text-zinc-50 cursor-pointer">
            Terms & Conditions
          </p>
          <p className="hover:text-zinc-50 cursor-pointer">Privacy Policy</p>
        </div>
        <p className="text-zinc-50 pl-5 text-center mt-2 sm:mt-0">
          COPYRIGHT 2021 Orgosys Pvt. Ltd. | All rights reserved.
        </p>
      </div>
    </footer>
  );
};
