import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { ButtonDivider } from "../items/ButtonDivider";
import MainLayout from "../layouts/MainLayout";
import { ContactUs } from "../sections/ContactUs";
import { HomeAnimation } from "../sections/HomeAnimation";
import { HomeMap } from "../sections/HomeMap";
import { HomeOurSection } from "../sections/HomeOurSection";
import { HomeServices } from "../sections/HomeServices";
import { HomeStrategies } from "../sections/HomeStrategies";
import { HomeTechServices } from "../sections/HomeTechServices";

const HomeOld = () => {
  const scrollRef = useRef();
  const scrollContactUs = (e) => {
    e.current.scrollIntoView({
      behavior: "smooth",
    });
  };
  const { hash } = useLocation();
  useEffect(() => {
    console.log(hash === "#contactus");
    if (hash === "#contactus") scrollContactUs(scrollRef);
  }, [hash]);
  return (
    <MainLayout page="home">
      <HomeAnimation onClick={() => scrollContactUs(scrollRef)} />
      <HomeOurSection contactUs={() => scrollContactUs(scrollRef)} />
      {/* <ButtonCard
        text={
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. aenean commodo ligula Aenean massa eget."
        }
        label={"Play Video"}
        onClick={() => null}
      /> */}
      <HomeServices />
      <HomeTechServices />
      <HomeStrategies />
      <ButtonDivider
        text={
          "Need Help With Your IT Endeavors? Contact Us to Get a Pro Service!"
        }
        label={"Contact Us"}
        onClick={() => scrollContactUs(scrollRef)}
      />
      <HomeMap />
      <div className="pt-20" ref={scrollRef}>
        <ContactUs />
      </div>
    </MainLayout>
  );
};

export default HomeOld;
