import Portfolio from "./old/pages/Portfolio";
import { Routes as ReactRoutes, Route, BrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import HomeOld from "./old/pages/HomeOld";

function App() {
  return (
    <BrowserRouter>
      <ReactRoutes>
        <Route path="/*" element={<Home />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/old" element={<HomeOld />} />
      </ReactRoutes>
    </BrowserRouter>
  );
}

export default App;
