import Background from "../../assets/home-page-imgs/h1-rev2-background-img.jpeg";
import Map from "../../assets/home-page-imgs/h1-rev2-map-img.png";
import Boy from "../../assets/home-page-imgs/h1-rev-2-boy-img.png";
import Girl from "../../assets/home-page-imgs/h1-rev-2-girl-img.png";
import { MapImg } from "../items/MapImg";

export const HomeMap = () => {
  return (
    <div className="mt-20 relative h-[calc(100vw)] max-h-[550px] md:max-h-max md:h-[580px] xl:h-[660px]">
      <img
        data-aos="slide-up"
        className="object-cover w-screen z-0 bottom-0 absolute"
        src={Background}
        alt=""
      />
      <div className="mx-10 md:mx-auto pt-32 md:pt-44 xl:pt-10 relative w-[calc(100vw-5rem)] md:w-[683px] xl:w-[900px]">
        <div className="relative">
          <img data-aos="slide-up" src={Map} alt="" />
          <MapImg
            img={
              "https://cleversoft.qodeinteractive.com/wp-content/uploads/2020/08/world-map-people-img-10.png"
            }
            comment={
              "These guys are just the best! They met all our needs, and all within the given timeframe"
            }
            name={"Maria Pahalchuk"}
            className={"top-[10%] left-[50%]"}
            designation={"CEO, Dazhbog"}
          />
          <MapImg
            img={
              "https://cleversoft.qodeinteractive.com/wp-content/uploads/2020/08/world-map-people-img-10.png"
            }
            comment={
              "These guys are just the best! They met all our needs, and all within the given timeframe"
            }
            name={"Maria Pahalchuk"}
            className={"top-[20%] left-[23%]"}
            designation={"CEO, Dazhbog"}
          />
          <MapImg
            img={
              "https://cleversoft.qodeinteractive.com/wp-content/uploads/2020/08/world-map-people-img-10.png"
            }
            comment={
              "These guys are just the best! They met all our needs, and all within the given timeframe"
            }
            name={"Maria Pahalchuk"}
            className={"top-[33%] left-[13%]"}
            designation={"CEO, Dazhbog"}
          />
          <MapImg
            img={
              "https://cleversoft.qodeinteractive.com/wp-content/uploads/2020/08/world-map-people-img-10.png"
            }
            comment={
              "These guys are just the best! They met all our needs, and all within the given timeframe"
            }
            name={"Maria Pahalchuk"}
            className={"top-[28%] left-[80%]"}
            designation={"CEO, Dazhbog"}
          />
          <MapImg
            img={
              "https://cleversoft.qodeinteractive.com/wp-content/uploads/2020/08/world-map-people-img-10.png"
            }
            comment={
              "These guys are just the best! They met all our needs, and all within the given timeframe"
            }
            name={"Maria Pahalchuk"}
            className={"top-[44%] left-[67%]"}
            designation={"CEO, Dazhbog"}
          />
          <MapImg
            img={
              "https://cleversoft.qodeinteractive.com/wp-content/uploads/2020/08/world-map-people-img-10.png"
            }
            comment={
              "These guys are just the best! They met all our needs, and all within the given timeframe"
            }
            name={"Maria Pahalchuk"}
            className={"top-[78%] left-[52%]"}
            designation={"CEO, Dazhbog"}
          />
        </div>
        <img
          data-aos="slide-up"
          className="hidden md:block md:h-[11.5rem] xl:h-[16.5rem] absolute md:-bottom-20 xl:-bottom-40 -left-5 xl:-left-14"
          src={Boy}
          alt=""
        />
        <img
          data-aos="slide-up"
          className="h-[9.5rem] md:h-[11.5rem] xl:h-[16.5rem] z-30 absolute md:-bottom-20 xl:-bottom-40 right-0 md:-right-5 xl:-right-14"
          src={Girl}
          alt=""
        />
      </div>
    </div>
  );
};
