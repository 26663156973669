import { FiArrowRight } from "react-icons/fi";

export const MobileNavListItem = (props: {
  label: string;
  onClick: () => void;
}) => {
  return (
    <li
      onClick={() => props.onClick()}
      className="flex justify-between items-center hover:cursor-pointer hover:text-[#f87544] w-full transition-colors duration-300"
    >
      <span className=" font-semibold">{props.label}</span>
      <FiArrowRight />
    </li>
  );
};
