import { ImgCard1 } from "../items/ImgCard1";
import H1Img1 from "../../assets/home-page-imgs/h1-img-1.jpeg";
import H1Img2 from "../../assets/home-page-imgs/h1-img-2.jpeg";
import H1Img3 from "../../assets/home-page-imgs/h1-img-3.jpeg";
import { SectionDetails } from "../items/SectionDelatils";
import { useNavigate } from "react-router-dom";

export const HomeOurSection = (props: { contactUs?: () => void }) => {
  const navigate = useNavigate();
  return (
    <>
      <SectionDetails
        heading={"Managed IT Services Customized by Industries"}
        text={`Orgosys offers transformative technology and software solutions to startups, global brands & companies. Some of our major clients include Jubilee, Sam Switch, Submer, JFG Enterprise, Clykk & GreenHouse.`}
      />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 md:gap-9 mx-10 md:mx-auto mt-[70px] w-fit md:w-[740px] lg:w-[940px] xl:w-[1108px]">
        <ImgCard1
          img={H1Img1}
          heading={"Can We Help?"}
          subtext={"Our Services"}
          onClick={() => props.contactUs()}
        />
        <ImgCard1
          img={H1Img2}
          heading={"Partner With Us!"}
          subtext={"Our Expertise"}
          onClick={() => props.contactUs()}
        />
        <ImgCard1
          img={H1Img3}
          heading={"Success Stories"}
          subtext={"Our Clients"}
          onClick={() => navigate("/portfolio")}
        />
      </div>
    </>
  );
};
