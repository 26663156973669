export const SectionDetails = (props: { heading: string; text: string }) => {
  return (
    <div className="flex flex-col lg:flex-row gap-0 xl:justify-between w-fit xl:w-[1108px] mx-10 md:mx-auto mt-28">
      <h1 className="sm:w-[460px] text-4xl font-bold">{props.heading}</h1>
      <p className="xl:w-[548px] lg:w-[480px] md:w-[740px] w-auto text-[#536EA2] md:pr-24 lg:pl-12 lg:pr-0 pt-1 mt-6 lg:mt-0">
        {props.text}
      </p>
    </div>
  );
};
