interface Props {
  color?: string;
  text?: string;
  label: string;
}

export const Tag: React.FC<Props> = ({ color, text, label }) => {
  return (
    <div
      className={`rounded-full px-4 py-1 uppercase w-fit text-[10px] font-semibold ${
        color ?? "bg-zinc-100"
      } ${text ?? "text-[#11181f]"}`}
    >
      {label}
    </div>
  );
};
