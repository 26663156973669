export const PartialSectionDetails = (props: {
  heading: string;
  text: string;
}) => {
  return (
    <div className=" gap-0 xl:justify-between w-fit md:w-[740px] lg:w-[940px] xl:w-[1108px] mx-10 md:mx-auto py-8 md:py-14 pb-28 md:pb-40">
      <h1 className="text-4xl md:text-5xl xl:text-6xl font-bold">
        {props.heading}
      </h1>
      <p className="w-auto md:text-lg xl:text-xl text-[#536EA2] pt-1 mt-6 pr-10">
        {props.text}
      </p>
    </div>
  );
};
