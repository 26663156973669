import { useEffect, useState } from "react";
import { BsBatteryFull, BsReception4 } from "react-icons/bs";
import { TiWiFi } from "react-icons/ti";
import Clock from "react-live-clock";
import "./devices.min.css";

export const MobileView = (props: {
  image: string;
  className?: string;
  theme: "light" | "dark";
}) => {
  const [scale, setScale] = useState(0);
  const updateScale = () => {
    if (window.innerWidth < 768) setScale(0.75);
    else setScale(window.innerWidth / 5 / 427);
  };
  useEffect(() => {
    window.addEventListener("resize", updateScale);
    updateScale();
    return () => window.removeEventListener("resize", updateScale);
  }, []);
  return (
    <div className={`z-20 ${props.className}`}>
      <div className="mb-5 flex items-center flex-col sm:flex-row justify-center lg:justify-start lg:ml-28 [&>*]:mt-2 [&>*]:mr-5"></div>
      <div className="marvel-device iphone-x" style={{ zoom: scale }}>
        <div className="notch">
          <div className="camera"></div>
          <div className="speaker"></div>
        </div>
        <div className="top-bar"></div>
        <div className="sleep"></div>
        <div className="bottom-bar"></div>
        <div className="volume"></div>
        <div className="overflow">
          <div className="shadow shadow--tr"></div>
          <div className="shadow shadow--tl"></div>
          <div className="shadow shadow--br"></div>
          <div className="shadow shadow--bl"></div>
        </div>
        <div className="inner-shadow"></div>
        <div className={`screen`}>
          <div
            className={`w-full text-sm font-bold pt-3 flex justify-between h-8 px-5 ${
              props.theme === "dark"
                ? "text-white bg-black"
                : "text-black bg-gray-100"
            }`}
          >
            <span className="pl-2">
              <Clock format={"HH:mm"} ticking={true} />
            </span>
            <span className="flex text-lg gap-1">
              <BsReception4 />
              <TiWiFi />
              <BsBatteryFull />
            </span>
          </div>
          <img src={props.image} className="object-cover w-full" />
        </div>
      </div>
    </div>
  );
};
