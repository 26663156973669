import { FaRegStar, FaStar, FaStarHalfAlt } from "react-icons/fa";
import Quotes from "../../assets/quotes.png";

interface Props {
  info: any;
}

export const TestimonialCard: React.FC<Props> = ({ info }) => {
  return (
    <div className="bg-white relative rounded-lg pl-12 py-14 pr-16 z-10 shadow-lg shadow-zinc-100">
      <div className="flex space-x-4">
        <img
          className="w-32 h-32 object-cover rounded-full"
          src={info?.img}
          alt={info?.name}
        />
        <div className="space-y-2">
          <p className="text-3xl font-semibold text-[#f87544]">{info?.name}</p>
          <p className=" text-lg text-gray-400">{info?.designation}</p>
          <div className="flex text-amber-400 text-3xl space-x-2.5">
            {Array(5)
              .fill(5)
              .map((key, i) => {
                if (info?.rating >= i + 1) return <FaStar key={i} />;
                if (i + 1 - info?.rating !== 0)
                  return <FaStarHalfAlt key={i} />;
                else return <FaRegStar key={i} />;
              })}
          </div>
        </div>
      </div>
      <p className="mt-5 text-lg">{info?.review}</p>
      <img src={Quotes} className="absolute top-8 right-14 w-36" />
    </div>
  );
};
