export const MapImg = (props: {
  img: string;
  comment: string;
  name: string;
  className: string;
  designation: string;
}) => {
  return (
    <div
      className={`${props.className} rounded-full hover:rounded-tr-3xl hover:shadow-md group hover:bg-white p-1 pr-0 absolute w-fit h-fit`}
    >
      <div className="invisible opacity-0 group-hover:visible group-hover:opacity-100 transition-opacity duration-300 ease-in-out rounded-lg shadow-md bg-white w-60 sm:w-80 lg:w-[434px] absolute -top-52 sm:-top-40 lg:-top-32 -left-10 text-[#536EA2] py-4 px-5">
        <p>"{props.comment}"</p>
        <span className="mt-4 flex flex-col sm:flex-row gap-2">
          <p className="text-[#1a2942]">{props.name}</p>
          <span className="hidden sm:block">|</span>
          <p className="text-[#f87544]">{props.designation}</p>
        </span>
      </div>
      <img
        className="w-[86%] rounded-full mr-0"
        src={props.img}
        alt={props.name}
      />
    </div>
  );
};
