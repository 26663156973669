export const ImgCard2 = (props: {
  className?: string;
  img: string;
  heading: string;
  subtext: string;
  onClick: () => void;
}) => {
  return (
    <div
      className={`${
        props.className || ""
      } rounded-lg relative group overflow-hidden shadow-md hover:cursor-pointer h-[35vh]`}
    >
      <img src={props.img} className="object-fill h-full w-full" alt="" />
      <div className="absolute w-64 p-6 pt-5 -bottom-64 group-hover:bottom-0 bg-white rounded-tr-lg transition-all ease-in-out duration-500">
        <h2 className="text-xl font-bold ">{props.heading}</h2>
        <p className="text-[#f87544] text-sm ">{props.subtext}</p>
      </div>
    </div>
  );
};
