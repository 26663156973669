import { FiArrowRight } from "react-icons/fi";

export const ImgCard3 = (props: {
  img: string;
  heading: string;
  subtext: string;
  linktext: string;
  onClick: () => void;
}) => {
  return (
    <div className="rounded-lg group shadow-md hover:shadow-lg hover:cursor-pointer hover:-translate-y-2 transform duration-300 ease-out pt-10 overflow-hidden">
      <img className="mx-auto h-56" src={props.img} alt="" />
      <div className="p-10">
        <h2 className="text-2xl font-bold text-center">{props.heading}</h2>
        <p className="text-[#536EA2] text-center mt-2">{props.subtext}</p>
      </div>
      {/* <div className="relative border-t hover:bg-[#1a2942] hover:text-white py-5 px-11 flex justify-center transform duration-300 ease-out">
        <span className="font-bold text-sm mr-3">{props.linktext}</span>
        <div className="w-5 relative overflow-hidden flex items-center">
          <span className="absolute flex items-center -left-10 group-hover:left-0 transition-all ease-out duration-300">
            <FiArrowRight />
            <FiArrowRight className=" ml-6" />
          </span>
        </div>
      </div> */}
    </div>
  );
};
