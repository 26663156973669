export const ContactUs = (props: {}) => {
  return (
    <div id="contact-us" className="bg-[#feeadf] py-36">
      <div className="px-10 md:mx-auto w-full md:w-[740px] lg:w-[940px] xl:w-[1108px] py-8 md:py-12 md:pl-9 flex flex-col lg:flex-row justify-between gap-14">
        <div className="w-full md:w-[700px] lg:w-[480px] xl:w-[550px]">
          <h1 className="max-w-[550px] text-4xl font-bold">
            Don’t Trust On-Site Contact Forms? Use These Instead.
          </h1>
          <div className="flex flex-col mt-4 gap-3">
            <div className="hover:text-[#f87544] hover:cursor-pointer">
              B-9, Gali No. 6/1, West Arjun Nagar <br />
              Delhi, India 110029
            </div>
            <div className="flex">
              <span>Mail: &nbsp;</span>
              <div className="hover:text-[#f87544] hover:cursor-pointer">
                sales@orgosys.com
              </div>
            </div>
            <div className="flex">
              <span>Phone: &nbsp;</span>
              <div className="hover:text-[#f87544] hover:cursor-pointer">
                +91 99539 40590
              </div>
            </div>
          </div>
        </div>
        <form className="w-full md:w-[700px] lg:w-[480px] xl:w-[550px] flex flex-col gap-2.5">
          <div className="gap-2.5 flex flex-col sm:flex-row text-lg w-full">
            <input
              key="name"
              className="w-full sm:w-[calc(50%-5px)] py-3 px-4 rounded-lg"
              placeholder="Full Name*"
              required
            />
            <input
              key="email"
              className="w-full sm:w-[calc(50%-5px)] py-3 px-4 rounded-lg"
              placeholder="Email*"
              required
            />
          </div>
          <textarea
            key="message"
            className="w-full text-lg py-3 px-4 rounded-lg"
            placeholder="Your Message*"
            rows={4}
            required
          />
          <button className="px-11 py-5 rounded-lg bg-[#1a2942] text-white font-semibold w-fit hover:text-[#f87544] hover:-translate-y-0.5 transform duration-300 ease-out">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};
