import { Button } from "../items/Button";
import { Tag } from "../items/Tag";
import MainImg from "../../assets/main-img.png";

export const HomeMainSection = (props: { contactUs: () => void }) => {
  console.log(window.innerWidth);
  return (
    <div className="lg:flex px-14 lg:pr-0 lg:pl-32 lg:flex-row-reverse">
      <div className="lg:w-1/2">
        <img src={MainImg} className="object-left-bottom object-cover" />
      </div>
      <div className="lg:w-1/2 lg:pr-5 pt-5 mb-10 lg:pt-44 space-y-6">
        <Tag label="management" />
        <h1 style={{ lineHeight: 1.5 }} className=" text-5xl font-semibold">
          Innovative {window.innerWidth >= 1024 && <br />} Tech-Driven Solutions
          For <span className="text-[#f87544]">Inventory</span> And{" "}
          <span className="text-[#f87544]">Warehousing</span>
        </h1>
        <p style={{ lineHeight: 2 }} className="text-lg">
          Diligently orchestrated supply chains are powered by intelligent
          delivery platforms using digitization for increasing operational
          efficiency.
        </p>
        <Button
          className="shadow-2xl w-fit shadow-[#f8744466]"
          label="Get in Touch"
          onClick={() => props.contactUs()}
        />
      </div>
    </div>
  );
};
