import { ReactNode } from "react";

export const ImgCard4 = (props: {
  img: string;
  heading: string;
  content: ReactNode;
}) => {
  return (
    <div className="flex flex-col md:flex-row justify-between items-center">
      <div className="hidden lg:block py-10 xl:py-20 pl-20 xl:pl-24 w-72 self-start">
        <h2 className="text-2xl lg:text-3xl xl:text-4xl font-bold">
          {props.heading}
        </h2>
      </div>
      <div className="p-10 xl:p-20 hidden lg:block xl:text-xl">
        {props.content}
      </div>
      <div className="lg:hidden p-10">
        <h2 className="text-2xl font-bold">{props.heading}</h2>
        <div className="p-5">{props.content}</div>
      </div>
      <img
        className="w-full md:w-[350px] lg:w-[400px] xl:w-[450px]"
        src={props.img}
        alt=""
      />
    </div>
  );
};
