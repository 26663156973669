interface Props {
  text: string;
  subtext: string;
}

export const StatCard: React.FC<Props> = ({ text, subtext }) => {
  return (
    <div className="flex flex-col w-full items-center justify-center ">
      <h1 className="text-[5rem] font-semibold">{text}</h1>
      <p className=" max-w-[11rem] text-center">{subtext}</p>
    </div>
  );
};
