import { ImgCard2 } from "../items/ImgCard2";
import Img1 from "../../assets/home-page-imgs/h1-port-single-img-1.jpeg";
import Img2 from "../../assets/home-page-imgs/h1-port-single-img-2.jpeg";
import Img3 from "../../assets/home-page-imgs/h1-port-single-img-3.jpeg";
import Img4 from "../../assets/home-page-imgs/h1-port-single-img-4.jpeg";
import Img5 from "../../assets/home-page-imgs/h1-port-single-img-5.jpeg";
import Img6 from "../../assets/home-page-imgs/h1-port-single-img-6.jpeg";
import Img7 from "../../assets/home-page-imgs/h1-port-single-img-7.jpeg";

export const HomeServices = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5 px-8 md:px-14 lg:px-20 mt-28">
      <ImgCard2
        className={"col-span-1 sm:col-span-2"}
        img={Img1}
        heading={"Fresh Start"}
        subtext={"Software Development"}
        onClick={() => null}
      />
      <ImgCard2
        img={Img2}
        heading={"Designing"}
        subtext={"Software Development"}
        onClick={() => null}
      />
      <ImgCard2
        img={Img3}
        heading={"Planning"}
        subtext={"Software Development"}
        onClick={() => null}
      />
      <ImgCard2
        img={Img4}
        heading={"Concepts"}
        subtext={"Software Development"}
        onClick={() => null}
      />
      <ImgCard2
        img={Img5}
        heading={"Ultimate"}
        subtext={"Software Development"}
        onClick={() => null}
      />
      <ImgCard2
        img={Img6}
        heading={"Clever Tech"}
        subtext={"Software Development"}
        onClick={() => null}
      />
      <ImgCard2
        img={Img7}
        heading={"Edit Anything"}
        subtext={"Software Development"}
        onClick={() => null}
      />
    </div>
  );
};
