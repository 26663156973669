import { Tag } from "./Tag";

interface Props {
  title: string;
  subtext: string;
  image?: string;
  className?: string | "";
}

export const FeatureContent: React.FC<Props> = ({
  title,
  subtext,
  className,
  image,
}) => {
  return (
    <div className={className}>
      <Tag label="features" color="bg-white" />
      {image?.length !== 0 && (
        <img className="mt-12 w-[80%]" src={image} alt={title} />
      )}
      <p
        className={` ${
          image?.length !== 0 ? "mt-4" : "mt-14"
        } text-xl font-semibold`}
      >
        {title}
      </p>
      <p className="mt-3 text-lg">{subtext}</p>
    </div>
  );
};
