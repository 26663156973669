import { ReactNode } from "react";
import { Button } from "./Button";
import { FeatureContent } from "./FeatureContent";

interface Props {
  title: string;
  subtext: string;
  image: string;
  className?: string | "";
  onClick: () => void;
}

export const FeatureCardwithButton: React.FC<Props> = ({
  title,
  subtext,
  className,
  image,
  onClick,
}) => {
  return (
    <div
      className={`py-20 px-14 pr-24  bg-zinc-50 rounded-xl relative z-10 flex ${className}`}
    >
      <img
        className="lg:w-[65%] align-bottom lg:mr-5 -ml-20 -mb-20"
        src={image}
        alt={title}
      />
      <div className="space-y-16 lg:w-1/2">
        <FeatureContent title={title} subtext={subtext} image={""} />
        <Button
          className="w-fit"
          label="Get in Touch"
          onClick={() => onClick()}
        />
      </div>
    </div>
  );
};
