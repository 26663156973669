import { useEffect, useState } from "react";
import Logo from "../../assets/OrgosysLogo.png";
import { BsFillMenuButtonWideFill } from "react-icons/bs";
import AOS from "aos";
import "../../../node_modules/aos/dist/aos.css";
import { MobileNavListItem } from "../items/MobileNavListItem";

interface Props {
  isSticky?: boolean;
  theme?: string;
  page: string;
}

export const Navbar: React.FC<Props> = ({ isSticky, theme, page }) => {
  const [hover, setHover] = useState(""),
    [showNav, setShowNav] = useState(true),
    [showNavMobile, setShowNavMobile] = useState(false);

  AOS.init({
    once: true,
  });

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 32) {
        setShowNav(false);
        setShowNavMobile(false);
      } else {
        setShowNav(true);
      }
    });
  }, []);

  return (
    <div data-aos="slide-down" className="fixed top-0 z-40 text-[#11181f]">
      <header
        className={`flex w-screen px-8 lg:px-32 h-[70px] lg:h-28 pt-12 items-center justify-between transition-all transform ease-in-out duration-300 ${
          isSticky ? "bg-white" : "bg-none"
        } ${showNav ? "visible translate-y-0" : "invisible -translate-y-1/2"}`}
      >
        <img className=" h-[30px]" src={Logo} alt="Orgosys" />
        <BsFillMenuButtonWideFill
          onClick={() => setShowNavMobile(!showNavMobile)}
          className={`md:hidden text-xl transition-color ease-in-out duration-300 ${
            showNavMobile ? "text-[#f87544]" : ""
          }`}
        />
        <nav
          className={`hidden md:block h-full max-w-xl md:w-[45%] xl:w-[35%]`}
        >
          <ul className="flex h-full justify-between">
            <li
              onMouseEnter={() => setHover("home")}
              onMouseLeave={() => setHover("")}
              className="h-full relative"
            >
              <a
                href="/"
                className={` font-semibold h-full flex items-center hover:cursor-pointer hover:text-[#f87544] transition-colors duration-300 ${
                  page === "home" && "text-[#f87544]"
                }`}
              >
                Home
              </a>
            </li>
            <li
              onMouseEnter={() => setHover("aboutus")}
              onMouseLeave={() => setHover("")}
              className="h-full relative"
            >
              <a
                href="/#features"
                className={` font-semibold h-full flex items-center hover:cursor-pointer hover:text-[#f87544] transition-colors duration-300 ${
                  page === "aboutus" && "text-[#f87544]"
                }`}
              >
                About Us
              </a>
            </li>
            <li
              onMouseEnter={() => setHover("services")}
              onMouseLeave={() => setHover("")}
              className="h-full relative"
            >
              <a
                href="/#services"
                className={` font-semibold h-full flex items-center hover:cursor-pointer hover:text-[#f87544] transition-colors duration-300 ${
                  page === "services" && "text-[#f87544]"
                }`}
              >
                Services
              </a>
            </li>
            <li
              onMouseEnter={() => setHover("portfolio")}
              onMouseLeave={() => setHover("")}
              className="h-full relative"
            >
              <a
                href="/portfolio"
                className={` font-semibold h-full flex items-center hover:cursor-pointer hover:text-[#f87544] transition-colors duration-300 ${
                  page === "portfolio" && "text-[#f87544]"
                }`}
              >
                Portfolio
              </a>
            </li>
            {(page !== "home" || isSticky) && (
              <li
                onMouseEnter={() => setHover("blog")}
                onMouseLeave={() => setHover("")}
                className="h-full relative"
              >
                <a
                  href="/#contactus"
                  className={` font-semibold h-full flex items-center hover:cursor-pointer hover:text-[#f87544] transition-colors duration-300 ${
                    false && "text-[#f87544]"
                  }`}
                >
                  Contact Us
                </a>
              </li>
            )}
          </ul>
        </nav>
      </header>
      <nav
        className={`md:hidden transition-all transform ease-in-out duration-300 origin-top ${
          showNavMobile ? "visible scale-100" : "invisible scale-0"
        }`}
      >
        <ul className="p-8 pt-4 bg-white shadow-md">
          <MobileNavListItem label="Home" onClick={() => null} />
          <MobileNavListItem label="Pages" onClick={() => null} />
          <MobileNavListItem label="Portfolio" onClick={() => null} />
          <MobileNavListItem label="Blog" onClick={() => null} />
          <MobileNavListItem label="Shop" onClick={() => null} />
        </ul>
      </nav>
    </div>
  );
};
