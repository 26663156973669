import { ReactNode } from "react";
import { FeatureContent } from "./FeatureContent";

interface Props {
  title: string;
  subtext: string;
  image?: string;
  className?: string | "";
  icon: ReactNode;
}

export const FeatureCard: React.FC<Props> = ({
  title,
  subtext,
  className,
  image,
  icon,
}) => {
  return (
    <div
      className={`p-16 pl-12 pr-20 bg-zinc-50 rounded-xl relative ${className}`}
    >
      <FeatureContent title={title} subtext={subtext} image={image ?? ""} />
      <div className="absolute top-8 right-14 rounded-full p-6 bg-white text-3xl text-[#f87544]">
        {icon}
      </div>
    </div>
  );
};
