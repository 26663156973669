import H1Img1 from "../../assets/home-page-imgs/h1-img-4.jpeg";
import H1Img2 from "../../assets/home-page-imgs/h1-img-5.jpeg";
import H1Img3 from "../../assets/home-page-imgs/h1-img-6.jpeg";
import { ImgCard3 } from "../items/ImgCard3";

export const HomeStrategies = () => {
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 md:gap-5 mx-10 md:mx-auto mt-28 w-auto md:w-[740px] lg:w-[940px] xl:w-[1108px]">
        <ImgCard3
          img={H1Img1}
          heading={"Skilled techies,"}
          subtext={"in-house"}
          linktext={"What Kind of Account?"}
          onClick={() => null}
        />
        <ImgCard3
          img={H1Img2}
          heading={"Definite process,"}
          subtext={"extraordinary results"}
          linktext={"Our Awesome Card?"}
          onClick={() => null}
        />
        <ImgCard3
          img={H1Img3}
          heading={"Unmatched transparency,"}
          subtext={"offering total visibility"}
          linktext={"Why Use the App?"}
          onClick={() => null}
        />
      </div>
    </>
  );
};
