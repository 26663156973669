import { useState } from "react";
import Logo from "../../assets/OrgosysLogo.png";
import LogoDark from "../../assets/OrgosysLogoDark.png";
import { FaFacebook, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { HiLocationMarker, HiMail, HiMap, HiPhone } from "react-icons/hi";

interface Props {
  theme?: string;
}

export const Footer: React.FC<Props> = ({ theme }) => {
  return (
    <footer className="w-screen absolute bottom-0">
      <div className="bg-[#1a2942] py-24 text-white items-center flex flex-col md:flex-row justify-evenly gap-5">
        <div className="w-[19rem]">
          <img className=" h-7" src={LogoDark} alt="Orgosys" />
          <div className="mt-5 text-gray-300">
            Orgosys is a professional Software Development Company based in
            India that offers customised software solution services at very
            competitive price.
          </div>
          <div className="w-fit flex mt-7 text-[#1F2C3C] gap-2.5">
            <div className="p-2 rounded-full bg-white hover:text-[#f87544] transition duration-300 ease-out hover:-translate-y-0.5 hover:cursor-pointer">
              <FaFacebook />
            </div>
            <div className="p-2 rounded-full bg-white hover:text-[#f87544] transition duration-300 ease-out hover:-translate-y-0.5 hover:cursor-pointer">
              <FaTwitter />
            </div>
            <div className="p-2 rounded-full bg-white hover:text-[#f87544] transition duration-300 ease-out hover:-translate-y-0.5 hover:cursor-pointer">
              <FaLinkedinIn />
            </div>
          </div>
        </div>
        <div className="w-[19rem] md:w-fit">
          <h2 className="font-semibold text-2xl">Contact Info</h2>
          <div className="text-gray-300 flex flex-col mt-4 gap-3">
            <div className="hover:text-white hover:cursor-pointer flex">
              <HiLocationMarker className="mt-1 mr-2" />
              B-9, Gali No. 6/1, West Arjun Nagar
            </div>
            <div className="hover:text-white hover:cursor-pointer flex">
              <HiMap className="mt-1 mr-2" />
              Delhi, India 110029
            </div>
            <div className="hover:text-white hover:cursor-pointer flex">
              <HiMail className="mt-1 mr-2" />
              sales@orgosys.com
            </div>
            <div className="hover:text-white hover:cursor-pointer flex">
              <HiPhone className="mt-1 mr-2" />
              +91 99539 40590
            </div>
          </div>
        </div>
        <div className="w-[19rem] md:w-fit">
          <h2 className="font-semibold text-2xl">Useful Links</h2>
          <div className="text-gray-300 flex flex-col mt-4 gap-3">
            <div className="hover:text-white hover:cursor-pointer">
              About Us
            </div>
            <div className="hover:text-white hover:cursor-pointer">
              Services
            </div>
            <div className="hover:text-white hover:cursor-pointer">
              Career Page
            </div>
            <div className="hover:text-white hover:cursor-pointer">
              Our Location
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#131d2d] py-8 text-gray-400 full text-center">
        COPYRIGHT ©. Orgosys Pvt. Ltd. | All rights reserved.
      </div>
    </footer>
  );
};
