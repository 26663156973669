import { FiEye, FiMessageCircle, FiUserCheck } from "react-icons/fi";
import { RightLeftArror } from "../items/RightLeftArrow";
import { Tag } from "../items/Tag";
import Dots from "../../assets/grey-dots.png";
import { useState } from "react";

const content = [
  {
    icon: <FiEye />,
    title: "Visibility & efficiency",
    text: "Tracking real-time delivery status and other updates on the supply chain process for better transparency. These are backed with reports and analytics to help evaluate in improving the supply chain processes and be able to deliver insights.",
  },
  {
    icon: <FiMessageCircle />,
    title: "Identifying flaws along with improved communication",
    text: "Alerts in real-time prevent loopholes in the inventory, cost slippages and operations, with better communication through automation received via status updates to all concerned personnel seamlessly.",
  },
  {
    icon: <FiUserCheck />,
    title: "Enhanced customer experience with advanced solutions",
    text: "Customers receive real-time status updates on shipment details and track their delivery. Employing such improved and interactive solutions easily integrate with advanced technologies trending in the future.",
  },
];

export const ServiceSection = () => {
  const [active, setActive] = useState(0);

  const pre = () => {
    setActive((prev) => {
      return prev === 0 ? 2 : prev - 1;
    });
  };

  const next = () => {
    setActive((prev) => {
      return prev === 2 ? 0 : prev + 1;
    });
  };

  return (
    <div className="bg-gradient-to-r from-[#1a242e] to-[#191716] relative -mt-16 pb-48 pt-56 lg:flex items-center lg:justify-between px-14 lg:px-32">
      <div className="lg:w-[50%] pr-20 text-white">
        <Tag label="services" color="bg-white" />
        <h1
          style={{ lineHeight: 1.5 }}
          className="text-[56px] w-[70%] mt-5 font-semibold"
        >
          Services That We Provide
        </h1>
        <p className="text-lg mt-6">
          Orgosys has worked on a smart inventory and warehouse management
          system that looks into the configurable supply chain process. We have
          been giving the power to brands to control operational costs while
          providing excellent customer service. The software solutions have an
          advanced logic-based stock put away and collection system, enabling
          intelligent and direct stock movement features. The companies
          empowered by these services have full integration of their sales,
          purchasing, logistics and other efficient mechanisms in place.
        </p>
        <RightLeftArror
          className="mt-10"
          onBack={() => pre()}
          onForward={() => next()}
        />
      </div>
      <div className="lg:w-[55%] h-fit  py-20 px-28 rounded-xl rounded-tl-[10rem] bg-white">
        <div className="w-fit rounded-full p-7 mb-12 bg-[#fff1ed] text-4xl text-[#f87544]">
          {content[active].icon}
        </div>
        <p className="text-xl font-semibold">{content[active].title}</p>
        <p className="mt-3 text-lg">{content[active].text}</p>
      </div>
      <img src={Dots} className="absolute -top-32 -right-28 w-72" />
      <img src={Dots} className="absolute -bottom-32 -left-28 w-72" />
    </div>
  );
};
