export const ButtonDivider = (props: {
  text: string;
  label: string;
  onClick: () => void;
}) => {
  return (
    <div className="bg-[#1a2942] mt-32">
      <div className="px-10 md:mx-auto w-fit md:w-[740px] lg:w-[940px] xl:w-[1108px] py-8 md:py-12 md:pl-9 md:flex justify-between gap-14 items-center">
        <p className="text-white font-bold text-2xl">{props.text}</p>
        <button
          onClick={() => props.onClick()}
          className="px-7 py-3 rounded-lg bg-[#f87544] hover:brightness-105 text-white font-semibold mt-5 md:mt-0"
        >
          {props.label}
        </button>
      </div>
    </div>
  );
};
