import { ImgCard4 } from "../items/ImgCard4";
import { PartialSectionDetails } from "../items/PartialSectionDetails";
import OurSectionImg from "../../assets/home-page-imgs/OurServices.jpeg";
import TechStackImg from "../../assets/home-page-imgs/TechStack.jpg";
import { Link } from "react-router-dom";
import { ArrowLink } from "../items/ArrowLink";

export const HomeTechServices = () => {
  return (
    <>
      <div className="relative w-full mt-28 -z-20">
        <div className="bg-[#feeadf] absolute top-0 left-0 bottom-0 w-[94%] -z-50 rounded-r-lg" />
        <PartialSectionDetails
          heading={" What we can do for you?"}
          text={`Our strength lies in the comprehensive suite of services we offer to our clients. From full-stack development to just consultancy- you demand, we deliver.`}
        />
      </div>
      <div className="-mt-20 md:-mt-[6.5rem] ml-10 md:ml-[calc((100vw-740px)/2)] lg:ml-[calc((100vw-940px)/2)] xl:ml-[calc((100vw-1108px)/2)] rounded-l-lg border bg-white shadow-md divide-y overflow-hidden">
        <ImgCard4
          img={OurSectionImg}
          heading={"Our Services"}
          content={
            <ul className="list-disc [&>*]:mt-2">
              <li>
                <Link to="">Software Development Services</Link>
              </li>
              <li>
                <Link to="">Blockchain Application Development</Link>
              </li>
              <li>
                <Link to="">Cloud Services</Link>
              </li>
              <li>
                <Link to="">Data Science Analytics Services</Link>
              </li>
              <li>
                <Link to="">Digital Transformation Services</Link>
              </li>
              <li>
                <Link to="">Business Intelligence Service</Link>
              </li>
              <li>
                <Link to="">AI Software Development Services</Link>
              </li>
              {/* <li className="list-none">
                <ArrowLink linktext={"View All"} onClick={() => null} />
              </li> */}
            </ul>
          }
        />
        <ImgCard4
          img={TechStackImg}
          heading={"Technology"}
          content={
            <div className="flex gap-16">
              <ul className="list-disc [&>*]:mt-2">
                <li>
                  <Link to="">Kotlin</Link>
                </li>
                <li>
                  <Link to="">Swiftt</Link>
                </li>
                <li>
                  <Link to="">Blockchain</Link>
                </li>
                <li>
                  <Link to="">React Natives</Link>
                </li>
                <li>
                  <Link to="">Flutter</Link>
                </li>
                {/* <li className="list-none">
                  <ArrowLink linktext={"View All"} onClick={() => null} />
                </li> */}
              </ul>
              <ul className="list-disc [&>*]:mt-2">
                <li>
                  <Link to="">AI/ML</Link>
                </li>
                <li>
                  <Link to="">Python</Link>
                </li>
                <li>
                  <Link to="">TensorFlow</Link>
                </li>
                <li>
                  <Link to="">Angular</Link>
                </li>
                <li>
                  <Link to="">Vue.js</Link>
                </li>
              </ul>
            </div>
          }
        />
      </div>
    </>
  );
};
