import React, { ReactNode, useEffect, useState } from "react";
import { FiArrowUp } from "react-icons/fi";
import { Footer } from "../sections/Footer";
import { Navbar } from "../sections/Navbar";

interface Props {
  children: ReactNode;
  page: string;
}

const MainLayout: React.FC<Props> = ({ children, page }) => {
  const [showButton, setShowButton] = useState(false),
    [scrollHover, setScrollHover] = useState(false),
    [showNav, setShowNav] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
      if (window.pageYOffset > 800) {
        setShowNav(true);
      } else {
        setShowNav(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="overflow-hidden flex flex-col justify-between relative">
      <Navbar isSticky={showNav} page={page} />
      <div className="min-h-screen h-fit">{children}</div>
      <Footer />
      <div className="right-8 bottom-[3%] fixed">
        <button
          onMouseEnter={() => setScrollHover(true)}
          onMouseLeave={() => setScrollHover(false)}
          onClick={scrollToTop}
          className={`z-10 transition-all duration-500 rounded ease-in-out h-11 w-11 bg-[#11181f] overflow-hidden relative hover:bg-[#f87544] ${
            showButton ? "opacity-100" : "opacity-0"
          }`}
        >
          <span
            className={`absolute left-[14px] transition-all ease-out duration-300 ${
              scrollHover ? "-top-4" : "top-[14px]"
            }`}
          >
            <FiArrowUp className="text-white hover:shadow-md " />
            <FiArrowUp className="text-white hover:shadow-md  mt-3" />
          </span>
        </button>
      </div>
    </div>
  );
};

export default MainLayout;

// pb-[1240px] sm:pb-[1019px] md:pb-[765px] xl:pb-[555px]
