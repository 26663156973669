import { FiArrowRight } from "react-icons/fi";

export const ImgCard1 = (props: {
  img: string;
  heading: string;
  subtext: string;
  onClick: () => void;
}) => {
  return (
    <div
      className="relative rounded-lg group hover:cursor-pointer"
      onClick={() => props.onClick()}
    >
      <div className=" group-hover:-translate-y-2 transform duration-300 ease-out ">
        <img src={props.img} alt="" className="" />
      </div>
      <div className="absolute -bottom-4 -right-4 rounded-lg shadow-md group-hover:shadow-lg p-6 pt-5 w-[85%] bg-white group-hover:translate-y-2 transform duration-300 ease-out ">
        <h2 className="text-xl font-bold ">{props.heading}</h2>
        <p className="text-[#f87544] text-sm ">{props.subtext}</p>
        <button
          onClick={() => props.onClick()}
          className="absolute w-[60px] h-[60px] border-t border-l rounded-tl-lg rounded-br-lg right-0 bottom-0 hover:bg-[#1a2942] hover:text-[#f87544] overflow-hidden"
        >
          <span className="absolute flex top-5 transition-all ease-out duration-300 -left-5 group-hover:left-5">
            <FiArrowRight className="text-xl opacity-0 group-hover:opacity-100" />
            <FiArrowRight className="text-xl opacity-100 group-hover:opacity-0 ml-4" />
          </span>
        </button>
      </div>
    </div>
  );
};
